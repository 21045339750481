import { route } from 'preact-router'
import { useContext, useEffect, useRef, useState } from 'preact/hooks'

import { AuthContext } from '../../contexts'
import { Button, Card, CheckBox, Chip, Grid, Icon, TextField } from '../../components/mdl'

import formBuilderClient from '../../components/form-builder'
import Anonymous from '../../components/anonymous'
import { storageLoad, storageSave } from '../../components/storage'

export default function Login (props) {
  const authContext = useContext(AuthContext)

  if (formBuilderClient.isLoggedIn(authContext)) {
    route(props.next || '/')
    return null
  }

  const [error, setError] = useState({})
  const [showPassword, setShowPassword] = useState(false)

  const formRef = {
    username: useRef(),
    password: useRef(),
    trust: useRef()
  }

  const [formData, setFormData] = useState({
    username: props.u,
    password: undefined
  })

  const trust = storageLoad('trust', false)

  const onSubmit = () => {
    formData.username = formRef.username.current.getValue().value
    formData.password = formRef.password.current.getValue().value

    const allIn = formData.username && formData.password

    if (allIn) {
      formBuilderClient
        .login(formData.username, formData.password, authContext)
        .then(rc => {
          if (rc) {
            route(props.next || '/')
          }
        })
        .catch(err => {
          setFormData(formData)
          setError(err)
        })
    }
  }

  const handleFormKeypress = e => {
    if (e.key === 'Enter') {
      onSubmit()
    }
  }

  const onDataChanged = e => {
    storageSave('trust', formRef.trust.current.getValue())
  }

  const onForgotPassword = () => {
    route('/set-password/request')
  }

  // const onRegister = () => {
  //   setError('Registration Closed. Please contact administrator.')
  //   route('/register')
  // }

  const togglePasswordVisibility = () => {
    // Reset the form data
    const formDataTemp = {}
    formDataTemp.username = formRef.username.current.getValue().value
    formDataTemp.password = formRef.password.current.getValue().value
    setFormData(formDataTemp)
    setShowPassword(!showPassword)
  }

  useEffect(() => {
    if (error.field) {
      formRef[error.field].current.setCustomError(error.message)
    }
  }, [error])

  const passwordToggleButton = () => {
    return (
      <a
        href='#'
        className='password-show'
        onClick={togglePasswordVisibility}
      >
        <i class='material-icons'>
          {showPassword ? 'visibility' : 'visibility_off'}
        </i>
      </a>
    )
  }

  return (
    <Anonymous>
      <Card class='auth-card'>
        <Card.Title class='mdl-card--border'>
          <Card.TitleText>Login</Card.TitleText>
        </Card.Title>
        <Card.Text class='mdl-card--border'>
          <Grid class='form--1-col'>
            <Grid.Cell>
              {/* <p>
                This year, we have launched a new application platform for Malengo. You must <a href='' onClick={onRegister}>register</a> with a valid email address to start the application process.
              </p> */}
              <p style={{ color: 'red' }}>
                Applications for the 2024–2025 intake are now closed.
              </p>
            </Grid.Cell>
            <Grid.Cell>
              <TextField
                ref={formRef.username}
                required
                floating-label
                label='Username or email address'
                type='text'
                name='username'
                value={formData.username}
                onKeyPress={e => handleFormKeypress(e)}
              />
            </Grid.Cell>
            <Grid.Cell>
              <div className='mdl-password-wrapper'>
                <TextField
                  ref={formRef.password}
                  required
                  floating-label
                  label='Password'
                  type={showPassword ? 'text' : 'password'}
                  name='password'
                  value={formData.password}
                  onKeyPress={e => handleFormKeypress(e)}
                />
                {passwordToggleButton()}
              </div>
            </Grid.Cell>
            <Grid.Cell>
              <CheckBox
                ref={formRef.trust}
                helpText='Check this to keep your data locally in case of lost connection'
                checked={trust}
                onChange={onDataChanged}
              >
                This is a trusted device.
              </CheckBox>
            </Grid.Cell>
          </Grid>
          {error.message && !error.field && (
            <Chip
              contact-class='mdl-color--accent mdl-color-text--white'
              contact={<Icon icon='error-outline' />}
              text={error.message}
            />
          )}
        </Card.Text>
        <Card.Actions class='mdl-dialog__actions'>
          <Button raised primary onClick={onSubmit}>
            Login
          </Button>
          <div class='mdl-layout-spacer' />
          <Button accent onClick={onForgotPassword}>
            Forgot your password?
          </Button>
        </Card.Actions>
      </Card>
      {/* <div class='auth-text'>
        Not registered yet?&nbsp;
        <a href='' onClick={onRegister}>Register now!</a>
      </div>  */}
    </Anonymous>
  )
}
